<template>
  <div>
    <section class="product-list-heading mb-0">
      <br /><br />
      <div class="container text-center" style="max-width: 500px">
        <h3 class="mb-4">REGISTER MERCHANT</h3>
        <form
          @submit.prevent="handleSubmit"
          class="center-form"
          autocomplete="off"
          novalidate="true"
        >
          <!-- Store Name -->
          <MyInputFormText
            id="full-name"
            label="Store Name"
            v-model="$v.storeName.$model"
            placeholder="Store Name"
            :classComponent="{
              'is-invalid-custom': $v.storeName.$error,
              'is-valid-custom': !$v.storeName.$invalid,
            }"
            :invalidMessage="[
              !$v.storeName.required ? 'Butuh nama toko kamu nih' : null,
            ]"
            redAsterisk="true"
          />
          <!-- <div class="input-line">
            <input
              type="text"
              name="storeName"
              v-model="$v.storeName.$model"
              class="form-control"
              :class="{
                'is-invalid-custom': $v.storeName.$error,
                'is-valid-custom': !$v.storeName.$invalid,
              }"
            />
            <div class="valid-feedback text-right">
              Your Store Name is valid!
            </div>
            <div class="invalid-feedback text-right">
              <p class="error" v-if="!$v.storeName.required">
                Store Name is required.
              </p>
            </div>
            <label for="storeName">Store Name *</label>
          </div> -->
          <!-- Merchant URL -->
          <MyInputFormText
            id="merchant-url"
            label="Store URL"
            v-model="$v.merchantURL.$model"
            placeholder="Full Name"
            :classComponent="{
              'is-invalid-custom': $v.merchantURL.$error,
              'is-valid-custom':
                !$v.merchantURL.$invalid && $v.merchantURL.$model,
            }"
            :invalidMessage="[
              !$v.merchantURL.url
                ? 'Masukin url yu! (Contoh : https://storeURL.com)'
                : null,
            ]"
          />
          <!-- <div class="input-line">
            <input
              type="text"
              name="merchantURL"
              v-model="$v.merchantURL.$model"
              class="form-control"
              :class="{
                'is-invalid-custom': $v.merchantURL.$error,
                'is-valid-custom': !$v.merchantURL.$invalid && $v.merchantURL.$model,
              }"
            />
            <div class="valid-feedback text-right">
              <template v-if="$v.merchantURL.$model">
                URL is valid!
              </template>
            </div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.merchantURL.url">
                Invalid URL. (E.g. : https://storeURL.com)
              </p>
            </div>
            <label for="merchantURL">Merchant URL *</label>
          </div> -->
          <!-- Email -->
          <MyInputFormText
            id="email"
            label="Email"
            type="email"
            v-model="$v.email.$model"
            placeholder="Email"
            :classComponent="{
              'is-invalid-custom': $v.email.$error,
              'is-valid-custom': !$v.email.$invalid,
            }"
            :invalidMessage="[
              !$v.email.required ? 'Butuh email kamu nih' : null,
              !$v.email.email ? 'Masukan format email kamu' : null,
            ]"
            redAsterisk="true"
          />
          <!-- <div class="input-line">
            <input
              type="text"
              name="email"
              v-model="$v.email.$model"
              class="form-control"
              :class="{
                'is-invalid-custom': $v.email.$error,
                'is-valid-custom': !$v.email.$invalid,
              }"
            />
            <div class="valid-feedback text-right">Your Email is valid!</div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.email.required">Email is required.</p>
              <p v-if="!$v.email.email">Your email is invalid.</p>
            </div>
            <label for="email">Email *</label>
          </div> -->
          <!-- Bank Account Number -->
          <MyInputFormText
            id="bank-Account"
            label="Bank Account Number (BCA)"
            v-model="$v.accountNumber.$model"
            placeholder="Bank Account Number (BCA)"
            :classComponent="{
              'is-invalid-custom': $v.accountNumber.$error,
              'is-valid-custom': !$v.accountNumber.$invalid,
            }"
            :invalidMessage="[
              !$v.accountNumber.required
                ? 'Butuh rekening bank kamu nih'
                : null,
              !$v.accountNumber.numeric && $v.accountNumber.required
                ? 'Masukan nomor yu!'
                : null,
            ]"
            redAsterisk="true"
          />
          <!-- <div class="input-line">
            <input
              type="number"
              name="accountNumber"
              v-model="$v.accountNumber.$model"
              class="form-control"
              :class="{
                'is-invalid-custom': $v.accountNumber.$error,
                'is-valid-custom': !$v.accountNumber.$invalid,
              }"
              :oninput="accountNumberLimit()"
              onkeydown="return event.keyCode !== 69"
            />

            <div class="valid-feedback text-right">
              Your Account Number is valid!
            </div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.accountNumber.required">
                Account Number is required.
              </p>
              <p v-if="!$v.accountNumber.numeric && $v.accountNumber.required">
                Your Account Number is invalid. Must be numeric!
              </p>
              <p v-if="!$v.accountNumber.minLength">
                Account Number must be
                {{ $v.accountNumber.$params.minLength.min }} numbers!
                {{
                  $v.accountNumber.$params.minLength.min -
                  $v.accountNumber.$model.length
                }}
                numbers left
              </p>
            </div>
            <label for="accountNumber">Bank Account Number (BCA) *</label>
          </div> -->
          <!-- Bank Account Name -->
          <MyInputFormText
            id="bank-Account"
            label="Bank Account Name"
            v-model="$v.accountName.$model"
            placeholder="Bank Account Name"
            :classComponent="{
              'is-invalid-custom': $v.accountName.$error,
              'is-valid-custom': !$v.accountName.$invalid,
            }"
            :invalidMessage="[
              !$v.accountName.required
                ? 'Butuh nama akun dari bank kamu nih'
                : null,
              !$v.accountName.namePattern ? 'Masukin text yu!' : null,
            ]"
            redAsterisk="true"
          />
          <!-- <div class="input-line">
            <input
              type="text"
              name="accountName"
              v-model="$v.accountName.$model"
              class="form-control"
              :class="{
                'is-invalid-custom': $v.accountName.$error,
                'is-valid-custom': !$v.accountName.$invalid,
              }"
            />
            <div class="valid-feedback text-right">
              Your Account Name is Valid!
            </div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.accountName.required">Account Name is required.</p>
              <p v-if="!$v.accountName.namePattern">
                Your Account Name is invalid.
              </p>
            </div>
            <label for="accountName">Bank Account Name *</label>
          </div> -->
          <!-- Password & Confirm Password -->
          <div class="row">
            <!-- Password (Left Side) -->
            <div class="col-md-6 pull-left">
              <MyInputFormText
                id="password"
                label="Password"
                type="password"
                v-model="$v.password.$model"
                placeholder="Password"
                :classComponent="{
                  'is-invalid-custom': $v.password.$error,
                  'is-valid-custom': !$v.password.$invalid,
                }"
                :invalidMessage="[
                  !$v.password.required ? 'Butuh kata sandi kamu nih' : null,
                  !$v.password.passwordPattern
                    ? 'Kata sandi kamu terlalu lemah, coba tambahin huruf dan angka yaa'
                    : null,
                  !$v.password.minLength
                    ? `Kata sandi kamu terlalu pendek nih, panjang teks minimum ${$v.password.$params.minLength.min}.`
                    : null,
                ]"
                redAsterisk="true"
              />
              <!-- <div class="input-line">
                <input
                  type="password"
                  name="password"
                  v-model="$v.password.$model"
                  class="form-control"
                  :class="{
                    'is-invalid-custom': $v.password.$error,
                    'is-valid-custom': !$v.password.$invalid,
                  }"
                />
                <div class="valid-feedback text-right">
                  Your Password is Valid!
                </div>
                <div class="invalid-feedback text-right">
                  <p v-if="!$v.password.required">Password is required.</p>
                  <p v-if="!$v.password.passwordPattern">
                    Your Password must have at least 1 letter, 1 number and 1
                    special character
                  </p>
                  <p v-if="!$v.password.minLength">
                    Password must be
                    {{ $v.password.$params.minLength.min }} characters!
                    {{
                      $v.password.$params.minLength.min -
                      $v.password.$model.length
                    }}
                    characters left
                  </p>
                </div>
                <label for="password">Password *</label>
              </div> -->
            </div>
            <!-- Confirm Password (Right Side) -->

            <div class="col-md-6 pull-right">
              <MyInputFormText
                id="confirm-password"
                label="Confirm Password"
                type="password"
                v-model="$v.confirmPassword.$model"
                placeholder="Confirm Password"
                :classComponent="{
                  'is-invalid-custom': $v.confirmPassword.$error,
                  'is-valid-custom': !$v.confirmPassword.$invalid,
                }"
                :invalidMessage="[
                  !$v.confirmPassword.required
                    ? 'Butuh konfirmasi kata sandi kamu nih'
                    : null,
                  !$v.confirmPassword.sameAsPassword &&
                  $v.confirmPassword.required
                    ? 'Kata sandi kamu harus cocok ya ..'
                    : null,
                ]"
                redAsterisk="true"
              />
              <!-- <div class="input-line">
                <input
                  type="password"
                  name="confirmPassword"
                  v-model="$v.confirmPassword.$model"
                  class="form-control"
                  :class="{
                    'is-invalid-custom': $v.confirmPassword.$error,
                    'is-valid-custom': !$v.confirmPassword.$invalid,
                  }"
                />
                <div class="valid-feedback text-right">
                  Your Confirmation Password is Valid!
                </div>
                <div class="invalid-feedback text-right">
                  <p v-if="!$v.confirmPassword.required">
                    Confirmation Password is required.
                  </p>
                  <p
                    v-if="
                      !$v.confirmPassword.sameAsPassword &&
                      $v.confirmPassword.required
                    "
                  >
                    Passwords must be identical!
                  </p>
                </div>
                <label for="confirmPassword">Confirm Password *</label>
              </div> -->
            </div>
          </div>
          <!-- Phone Number -->
          <MyInputFormText
            id="phone-number"
            label="Phone Number"
            v-model="$v.phone.$model"
            placeholder="Phone Number"
            :classComponent="{
              'is-invalid-custom': $v.phone.$error,
              'is-valid-custom': !$v.phone.$invalid,
            }"
            :invalidMessage="[
              !$v.phone.required ? 'Butuh nomor telepon kamu nih' : null,
              !$v.phone.phonePattern
                ? 'Nomor telepon kamu tidak valid :('
                : null,
              !$v.phone.minLength && $v.phone.phonePattern
                ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${$v.phone.$params.minLength.min}.`
                : null,
              !$v.phone.maxLength && $v.phone.phonePattern
                ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${$v.phone.$params.maxLength.max}.`
                : null,
            ]"
            redAsterisk="true"
            phonePrefix="true"
          />
          <!-- <div class="input-line">
            <input
              type="text"
              name="phone"
              v-model="$v.phone.$model"
              class="form-control"
              :class="{
                'is-invalid-custom': $v.phone.$error,
                'is-valid-custom': !$v.phone.$invalid,
              }"
            />
            <div class="valid-feedback text-right">
              Your Phone Number is Valid!
            </div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.phone.required">Phone Number is required.</p>
              <p v-if="!$v.phone.phonePattern">
                Your Phone Number is invalid! (E.g. : +628xxxxxxxx)
              </p>
              <p v-if="!$v.phone.minLength && $v.phone.phonePattern">
                Phone Number must have at least
                {{ $v.phone.$params.minLength.min }} characters!
                {{ $v.phone.$params.minLength.min - $v.phone.$model.length }}
                characters left
              </p>
              <p v-if="!$v.phone.maxLength && $v.phone.phonePattern">
                Phone Number must have less than
                {{ $v.phone.$params.maxLength.max }} characters!
              </p>
            </div>
            <label for="phone">Phone Number *</label>
          </div> -->
          <!-- Merchant Address -->
          <MyInputFormText
            id="address"
            label="Merchant Address"
            v-model="$v.address.$model"
            placeholder="Merchant Address"
            :classComponent="{
              'is-invalid-custom': $v.address.$error,
              'is-valid-custom': !$v.address.$invalid,
            }"
            :invalidMessage="[
              !$v.address.required ? 'Butuh alamat kamu nih' : null,
            ]"
            redAsterisk="true"
          />
          <!-- <div class="input-line">
            <input
              type="text"
              name="address"
              v-model="$v.address.$model"
              class="form-control"
              :class="{
                'is-invalid-custom': $v.address.$error,
                'is-valid-custom': !$v.address.$invalid,
              }"
            />
            <div class="valid-feedback text-right">Your Address is Valid!</div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.address.required">Address is required.</p>
            </div>
            <label for="address">Merchant Address *</label>
          </div> -->
          <!-- Select Province -->
          <MyInputFormSelect
            id="province"
            label="Select Province *"
            :options="provinceList"
            v-model="$v.selectedProvince.$model"
            placeholder="Select Province *"
            :classComponent="{
              'is-invalid-custom': $v.selectedProvince.$error,
              'is-valid-custom': !$v.selectedProvince.$invalid,
            }"
            :invalidMessage="[
              !$v.selectedProvince.required ? 'Butuh provinsi kamu nih' : null,
            ]"
            @input="getDistrictList"
          />
          <!-- <div class="input-line">
            <select
              name="province"
              v-model="selectedProvince"
              @change="getDistrictList($event)"
              class="form-control-custom"
              :class="{
                'is-invalid-custom': $v.selectedProvince.$error,
                'is-valid-custom': !$v.selectedProvince.$invalid,
              }"
              required
            >
              <option value="0" selected disabled>Select Province *</option>
              <option
                v-for="(province, index) in provinceList"
                :key="index"
                :value="province.code"
              >
                {{ province.name }}
              </option>
            </select>
            <div class="valid-feedback text-right">Your Province is Valid!</div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.selectedProvince.provinceIsValid">
                Province is required.
              </p>
            </div>
          </div> -->
          <!-- Select District -->
          <MyInputFormSelect
            :disabled="districtList.length == 0"
            id="district"
            label="Select District *"
            :options="districtList"
            v-model="$v.selectedDistrict.$model"
            placeholder="Select District *"
            :classComponent="{
              'is-invalid-custom': $v.selectedDistrict.$error,
              'is-valid-custom': !$v.selectedDistrict.$invalid,
            }"
            :invalidMessage="[
              !$v.selectedDistrict.required ? 'Butuh daerah kamu nih' : null,
            ]"
            @input="getSubDistrictList"
          />
          <!-- <div class="input-line">
            <select
              name="district"
              v-model="selectedDistrict"
              @change="getSubDistrictList($event)"
              class="form-control-custom"
              :class="{
                'is-invalid-custom': $v.selectedDistrict.$error,
                'is-valid-custom': !$v.selectedDistrict.$invalid,
              }"
              required
              :disabled="selectedProvince ? false : true"
            >
              <option value="0" selected disabled>Select District *</option>
              <option
                v-for="(district, index) in districtList"
                :key="index"
                v-bind:value="district.code"
              >
                {{ district.name }}
              </option>
            </select>
            <div class="valid-feedback text-right">Your District is Valid!</div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.selectedDistrict.districtIsValid">
                District is required.
              </p>
            </div>
          </div> -->
          <!-- Select Sub-District -->
          <MyInputFormSelect
            :disabled="subDistrictList.length == 0"
            id="sub-district"
            label="Select Sub District *"
            :options="subDistrictList"
            v-model="$v.selectedSubDistrict.$model"
            placeholder="Select Sub District *"
            :classComponent="{
              'is-invalid-custom': $v.selectedSubDistrict.$error,
              'is-valid-custom': !$v.selectedSubDistrict.$invalid,
            }"
            :invalidMessage="[
              !$v.selectedSubDistrict.required
                ? 'Butuh kecamatan kamu kamu nih'
                : null,
            ]"
          />
          <!-- <div class="input-line">
            <select
              name="subDistrict"
              v-model="selectedSubDistrict"
              class="form-control-custom"
              :class="{
                'is-invalid-custom': $v.selectedSubDistrict.$error,
                'is-valid-custom': !$v.selectedSubDistrict.$invalid,
              }"
              required
              :disabled="selectedDistrict ? false : true"
            >
              <option value="0" selected disabled>Select Sub District *</option>
              <option
                v-for="(subDistrict, index) in subDistrictList"
                :key="index"
                v-bind:value="subDistrict.code"
              >
                {{ subDistrict.name }}
              </option>
            </select>
            <div class="valid-feedback text-right">
              Your Sub District is Valid!
            </div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.selectedSubDistrict.subDisctrictIsValid">
                Sub District is required.
              </p>
            </div>
          </div> -->
          <!-- Postal Code -->
          <MyInputFormText
            id="postal-code"
            label="Postal Code"
            v-model="$v.postalCode.$model"
            placeholder="Postal Code"
            :maxlength="5"
            :classComponent="{
              'is-invalid-custom': $v.postalCode.$error,
              'is-valid-custom': !$v.postalCode.$invalid,
            }"
            :invalidMessage="[
              !$v.postalCode.required ? 'Butuh kode postal kamu nih' : null,
              !$v.postalCode.numeric && $v.postalCode.required
                ? 'Masukan nomor yu!'
                : null,
              !$v.postalCode.minLength
                ? `Kode postal kamu terlalu pendek nih, panjang teks minimum ${$v.postalCode.$params.minLength.min}`
                : null,
            ]"
            redAsterisk="true"
          />
          <!-- <div class="input-line">
            <input
              type="number"
              name="postalCode"
              v-model="$v.postalCode.$model"
              class="form-control"
              :class="{
                'is-invalid-custom': $v.postalCode.$error,
                'is-valid-custom': !$v.postalCode.$invalid,
              }"
              onkeydown="return event.keyCode !== 69"
              :oninput="postalCodeLimit()"
            />
            <div class="valid-feedback text-right">
              Your Postal Code is Valid!
            </div>
            <div class="invalid-feedback text-right">
              <p v-if="!$v.postalCode.required">Postal Code is required.</p>
              <p v-if="!$v.postalCode.numeric && $v.postalCode.required">
                Your Postal Code is invalid. Must be numeric!
              </p>
              <p v-if="!$v.postalCode.minLength">
                Postal Code must be
                {{ $v.postalCode.$params.minLength.min }} numbers!
                {{
                  $v.postalCode.$params.minLength.min -
                  $v.postalCode.$model.length
                }}
                numbers left
              </p>
            </div>
            <label for="postalCode">Postal Code *</label>
          </div> -->
          <!-- Courier -->
          <h5 class="no-margin-bottom">Courier selection</h5>
          <p>
            Select agents couriers and delivery services you want to use (could
            be more than one).
          </p>
          <div class="row d-flex justify-content-center p-1">
            <div
              class="col-3 p-1"
              v-for="(courier, index) in courierList"
              :key="index"
            >
              <div
                class="border p-1"
                :class="{ 'bg-info': handleModel(courier.id, true) != -1 }"
                style="cursor: pointer"
                @click="handleModel(courier.id)"
              >
                <img :src="courier.image_url" alt="" /><br />
                {{ courier.name }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row mt-4">
              <div class="col-md-8 offset-md-2">
                <p class="have-account mb-0">
                  By clicking the 'Register' below button I agree to the
                  <a href="javascript:void(0)" v-b-modal="'modal-term'"
                    >Terms of Use</a
                  >
                  of this site
                </p>
              </div>
            </div>
          </div>
          <div class="container my-3">
            <div class="col-md-12 text-center">
              <button
                class="btn btn-wz-proceed"
                style="display: inline; width: 65%"
              >
                REGISTER
              </button>
            </div>
          </div>
        </form>
      </div>
      <br /><br />
    </section>
    <ModalTermsOfService />
  </div>
</template>

<script>
import MyInputFormText from "@/components/form/MyInputFormText";
import MyInputFormSelect from "@/components/form/MyInputFormSelect";
import ModalTermsOfService from "@/components/modals/ModalTermsOfService.vue";
import axios from "axios";
import { toaster, regex } from "@/_helpers";
import { userService, addressService } from "@/_services";
import {
  required,
  url,
  email,
  numeric,
  minLength,
  maxLength,
  helpers,
  sameAs,
} from "vuelidate/lib/validators";

// Regex for Validation Start
const namePattern = helpers.regex("namePattern", regex.namePattern);
const passwordPattern = helpers.regex("passwordPattern", regex.passwordPattern);
const phonePattern = helpers.regex("phonePattern", regex.phonePattern);
// Regex for Validation End

export default {
  name: "RegisterMerchant",
  data() {
    return {
      courierList: [],
      selectedCourier: [],
      msg: [],
      storeName: "",
      merchantURL: "",
      email: "",
      accountNumber: "",
      accountName: "",
      password: "",
      confirmPassword: "",
      phone: "",
      address: "",
      provinceList: [],
      selectedProvince: null,
      districtList: [],
      selectedDistrict: null,
      subDistrictList: [],
      selectedSubDistrict: null,
      postalCode: "",

      signUpMerchantData: {
        name: "",
        url: "",
        email: "",
        account_number: "",
        account_alias: "",
        password: "",
        confirm_password: "",
        phone: "",
        address: "",
        region_id: 0,
        district_id: 0,
        township_id: 0,
        postal_code: "",
        shippings: [],
      },
    };
  },
  components: {
    MyInputFormText,
    MyInputFormSelect,
    ModalTermsOfService,
  },
  mounted() {
    this.getCourierList();
    this.getProvinceList();
  },
  validations: {
    storeName: {
      required,
    },
    merchantURL: {
      url,
    },
    email: {
      required,
      email,
    },
    accountNumber: {
      required,
      numeric,
    },
    accountName: {
      required,
      namePattern,
    },
    password: {
      required,
      minLength: minLength(6),
      passwordPattern,
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
    phone: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(14),
      phonePattern,
    },
    address: {
      required,
    },
    postalCode: {
      required,
      numeric,
      minLength: minLength(5),
    },
    selectedProvince: {
      required,
    },
    selectedDistrict: {
      required,
    },
    selectedSubDistrict: {
      required,
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
  },
  methods: {
    getCourierList() {
      axios
        .get(this.apiUrl + "users/cms/couriers", {
          headers: {
            api_key: this.apiKey,
          },
        })
        .then((res) => {
          this.courierList = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getProvinceList() {
      this.provinceList = [];
      try {
        const response = await addressService.getProvinceList();
        if (response.status === 200) {
          this.provinceList = this.setMyInputFormSelectOptionsAdress(
            response.data.data
          );
        } else {
          this.provinceList = [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getDistrictList(id, clear = true) {
      if (this.selectedProvince != null) {
        if (clear) {
          this.selectedDistrict = null;
          this.selectedSubDistrict = null;
        }
        this.districtList = [];
        this.subDistrictList = [];
        try {
          const response = await addressService.getDistrictList(id);
          if (response.status === 200) {
            this.districtList = this.setMyInputFormSelectOptionsAdress(
              response.data.data
            );
          } else {
            this.districtList = [];
            this.subDistrictList = [];
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getSubDistrictList(id, clear) {
      if (this.selectedDistrict != null) {
        if (clear) this.selectedSubDistrict = null;
        this.subDistrictList = [];
        try {
          const response = await addressService.getSubDistrictList(id);
          if (response.status === 200) {
            this.subDistrictList = this.setMyInputFormSelectOptionsAdress(
              response.data.data
            );
          } else {
            this.subDistrictList = [];
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    setMyInputFormSelectOptionsAdress(list) {
      let resultOptions = [];
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        resultOptions.push({ value: element.id, text: element.name });
      }
      return resultOptions;
    },
    handleModel(id, get) {
      const index = this.selectedCourier.findIndex(
        (el) => el.shipping_id == id
      );
      if (get) return index;
      if (index == -1) this.selectedCourier.push({ shipping_id: id });
      else this.selectedCourier.splice(index, 1);
    },
    handleSubmit() {
      const {
        storeName,
        merchantURL,
        email,
        accountNumber,
        accountName,
        password,
        confirmPassword,
        phone,
        address,
        selectedProvince,
        selectedDistrict,
        selectedSubDistrict,
        selectedCourier,
        postalCode,
      } = this;

      // Check Form Validation Start
      this.$v.$touch();
      if (this.$v.$invalid)
        toaster.make("Please Fill the Form Correctly", "danger");
      // Check Form Validation Start
      else {
        const par_ids = selectedCourier;
        if (par_ids.length == 0) toaster.make("Courier is mandatory", "danger");
        else {
          this.assignMerchantDataSignup(
            storeName,
            merchantURL,
            email,
            accountNumber,
            accountName,
            password,
            confirmPassword,
            phone,
            address,
            selectedProvince,
            selectedDistrict,
            selectedSubDistrict,
            par_ids,
            postalCode
          );
          userService.signUpMerchant(this.signUpMerchantData).then((value) => {
            toaster.make(value, /success/i.test(value) ? "success" : "danger");
          });
        }
      }
    },

    assignMerchantDataSignup(
      storeName,
      merchantURL,
      email,
      accountNumber,
      accountName,
      password,
      confirmPassword,
      phone,
      address,
      selectedProvince,
      selectedDistrict,
      selectedSubDistrict,
      par_ids,
      postalCode
    ) {
      this.signUpMerchantData.name = storeName;
      this.signUpMerchantData.url = merchantURL;
      this.signUpMerchantData.email = email;
      this.signUpMerchantData.account_number = accountNumber;
      this.signUpMerchantData.account_alias = accountName;
      this.signUpMerchantData.password = password;
      this.signUpMerchantData.confirm_password = confirmPassword;
      this.signUpMerchantData.phone = /\+62/.test(phone)
        ? phone
        : "+62" + phone;
      this.signUpMerchantData.address = address;
      this.signUpMerchantData.region_id = selectedProvince;
      this.signUpMerchantData.district_id = selectedDistrict;
      this.signUpMerchantData.township_id = selectedSubDistrict;
      this.signUpMerchantData.postal_code = postalCode;
      this.signUpMerchantData.shippings = par_ids;
    },
  },
};
</script>