<template>
  <b-modal 
    id="modal-term" 
    centered
    scrollable
    hide-footer
  >
    <template #modal-header>
      <!-- <h5 class="modal-title">Terms Conditions</h5> -->
      <button class="btn-close-modal-custom" @click="$bvModal.hide('modal-terms')">&times;</button>
    </template>
    <div v-html="terms" v-if="terms"></div>
    <div class="text-justify" v-else>
      <h5>Terms & Conditions</h5>
      <p>
        Merchant yang sudah menyetujui perjanjian kerja sama, mendapatkan
        kesempatan untuk dapat membuka toko atau menjual Produk di website
        Whizliz. Syarat dan Ketentuan ini mengatur akses Merchant terhadap
        website dan seluruh fitur yang disediakan oleh Whizliz
      </p>
      <p>
        Dengan mengakses dan/atau menggunakan website, Merchant telah membaca,
        mengerti dan setuju untuk mengikatkan diri pada Syarat dan Ketentuan
        Merchant. Jika Merchant tidak menyetujui Syarat dan Ketentuan, maka
        Merchant tidak diperkenankan untuk mengakses dan/atau menggunakan
        website sebagai Merchant.
      </p>
      <p>
        Dengan tunduk pada Syarat dan Ketentuan Merchant, Merchant juga
        dianggap mengikatkan diri dengan Syarat dan Ketentuan Whizliz sehingga
        seluruh ketentuan yang terdapat pada Syarat dan Ketentuan Merchant
        akan tetap berlaku bagi Merchant.
      </p>
      <p>
        Ketidakberlakuan syarat dan ketentuan lainnya tidak akan menyebabkan
        Syarat dan Ketentuan Merchant menjadi tidak sah, tidak berlaku, dan /
        atau tidak dapat dilaksanakan.
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalTermsOfService",
  props: ["terms"]
};
</script>