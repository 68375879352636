var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"product-list-heading mb-0"},[_c('br'),_c('br'),_c('div',{staticClass:"container text-center",staticStyle:{"max-width":"500px"}},[_c('h3',{staticClass:"mb-4"},[_vm._v("REGISTER MERCHANT")]),_c('form',{staticClass:"center-form",attrs:{"autocomplete":"off","novalidate":"true"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('MyInputFormText',{attrs:{"id":"full-name","label":"Store Name","placeholder":"Store Name","classComponent":{
            'is-invalid-custom': _vm.$v.storeName.$error,
            'is-valid-custom': !_vm.$v.storeName.$invalid,
          },"invalidMessage":[
            !_vm.$v.storeName.required ? 'Butuh nama toko kamu nih' : null,
          ],"redAsterisk":"true"},model:{value:(_vm.$v.storeName.$model),callback:function ($$v) {_vm.$set(_vm.$v.storeName, "$model", $$v)},expression:"$v.storeName.$model"}}),_c('MyInputFormText',{attrs:{"id":"merchant-url","label":"Store URL","placeholder":"Full Name","classComponent":{
            'is-invalid-custom': _vm.$v.merchantURL.$error,
            'is-valid-custom':
              !_vm.$v.merchantURL.$invalid && _vm.$v.merchantURL.$model,
          },"invalidMessage":[
            !_vm.$v.merchantURL.url
              ? 'Masukin url yu! (Contoh : https://storeURL.com)'
              : null,
          ]},model:{value:(_vm.$v.merchantURL.$model),callback:function ($$v) {_vm.$set(_vm.$v.merchantURL, "$model", $$v)},expression:"$v.merchantURL.$model"}}),_c('MyInputFormText',{attrs:{"id":"email","label":"Email","type":"email","placeholder":"Email","classComponent":{
            'is-invalid-custom': _vm.$v.email.$error,
            'is-valid-custom': !_vm.$v.email.$invalid,
          },"invalidMessage":[
            !_vm.$v.email.required ? 'Butuh email kamu nih' : null,
            !_vm.$v.email.email ? 'Masukan format email kamu' : null,
          ],"redAsterisk":"true"},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", $$v)},expression:"$v.email.$model"}}),_c('MyInputFormText',{attrs:{"id":"bank-Account","label":"Bank Account Number (BCA)","placeholder":"Bank Account Number (BCA)","classComponent":{
            'is-invalid-custom': _vm.$v.accountNumber.$error,
            'is-valid-custom': !_vm.$v.accountNumber.$invalid,
          },"invalidMessage":[
            !_vm.$v.accountNumber.required
              ? 'Butuh rekening bank kamu nih'
              : null,
            !_vm.$v.accountNumber.numeric && _vm.$v.accountNumber.required
              ? 'Masukan nomor yu!'
              : null,
          ],"redAsterisk":"true"},model:{value:(_vm.$v.accountNumber.$model),callback:function ($$v) {_vm.$set(_vm.$v.accountNumber, "$model", $$v)},expression:"$v.accountNumber.$model"}}),_c('MyInputFormText',{attrs:{"id":"bank-Account","label":"Bank Account Name","placeholder":"Bank Account Name","classComponent":{
            'is-invalid-custom': _vm.$v.accountName.$error,
            'is-valid-custom': !_vm.$v.accountName.$invalid,
          },"invalidMessage":[
            !_vm.$v.accountName.required
              ? 'Butuh nama akun dari bank kamu nih'
              : null,
            !_vm.$v.accountName.namePattern ? 'Masukin text yu!' : null,
          ],"redAsterisk":"true"},model:{value:(_vm.$v.accountName.$model),callback:function ($$v) {_vm.$set(_vm.$v.accountName, "$model", $$v)},expression:"$v.accountName.$model"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pull-left"},[_c('MyInputFormText',{attrs:{"id":"password","label":"Password","type":"password","placeholder":"Password","classComponent":{
                'is-invalid-custom': _vm.$v.password.$error,
                'is-valid-custom': !_vm.$v.password.$invalid,
              },"invalidMessage":[
                !_vm.$v.password.required ? 'Butuh kata sandi kamu nih' : null,
                !_vm.$v.password.passwordPattern
                  ? 'Kata sandi kamu terlalu lemah, coba tambahin huruf dan angka yaa'
                  : null,
                !_vm.$v.password.minLength
                  ? `Kata sandi kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.password.$params.minLength.min}.`
                  : null,
              ],"redAsterisk":"true"},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}})],1),_c('div',{staticClass:"col-md-6 pull-right"},[_c('MyInputFormText',{attrs:{"id":"confirm-password","label":"Confirm Password","type":"password","placeholder":"Confirm Password","classComponent":{
                'is-invalid-custom': _vm.$v.confirmPassword.$error,
                'is-valid-custom': !_vm.$v.confirmPassword.$invalid,
              },"invalidMessage":[
                !_vm.$v.confirmPassword.required
                  ? 'Butuh konfirmasi kata sandi kamu nih'
                  : null,
                !_vm.$v.confirmPassword.sameAsPassword &&
                _vm.$v.confirmPassword.required
                  ? 'Kata sandi kamu harus cocok ya ..'
                  : null,
              ],"redAsterisk":"true"},model:{value:(_vm.$v.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.confirmPassword, "$model", $$v)},expression:"$v.confirmPassword.$model"}})],1)]),_c('MyInputFormText',{attrs:{"id":"phone-number","label":"Phone Number","placeholder":"Phone Number","classComponent":{
            'is-invalid-custom': _vm.$v.phone.$error,
            'is-valid-custom': !_vm.$v.phone.$invalid,
          },"invalidMessage":[
            !_vm.$v.phone.required ? 'Butuh nomor telepon kamu nih' : null,
            !_vm.$v.phone.phonePattern
              ? 'Nomor telepon kamu tidak valid :('
              : null,
            !_vm.$v.phone.minLength && _vm.$v.phone.phonePattern
              ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.phone.$params.minLength.min}.`
              : null,
            !_vm.$v.phone.maxLength && _vm.$v.phone.phonePattern
              ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${_vm.$v.phone.$params.maxLength.max}.`
              : null,
          ],"redAsterisk":"true","phonePrefix":"true"},model:{value:(_vm.$v.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.phone, "$model", $$v)},expression:"$v.phone.$model"}}),_c('MyInputFormText',{attrs:{"id":"address","label":"Merchant Address","placeholder":"Merchant Address","classComponent":{
            'is-invalid-custom': _vm.$v.address.$error,
            'is-valid-custom': !_vm.$v.address.$invalid,
          },"invalidMessage":[
            !_vm.$v.address.required ? 'Butuh alamat kamu nih' : null,
          ],"redAsterisk":"true"},model:{value:(_vm.$v.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.address, "$model", $$v)},expression:"$v.address.$model"}}),_c('MyInputFormSelect',{attrs:{"id":"province","label":"Select Province *","options":_vm.provinceList,"placeholder":"Select Province *","classComponent":{
            'is-invalid-custom': _vm.$v.selectedProvince.$error,
            'is-valid-custom': !_vm.$v.selectedProvince.$invalid,
          },"invalidMessage":[
            !_vm.$v.selectedProvince.required ? 'Butuh provinsi kamu nih' : null,
          ]},on:{"input":_vm.getDistrictList},model:{value:(_vm.$v.selectedProvince.$model),callback:function ($$v) {_vm.$set(_vm.$v.selectedProvince, "$model", $$v)},expression:"$v.selectedProvince.$model"}}),_c('MyInputFormSelect',{attrs:{"disabled":_vm.districtList.length == 0,"id":"district","label":"Select District *","options":_vm.districtList,"placeholder":"Select District *","classComponent":{
            'is-invalid-custom': _vm.$v.selectedDistrict.$error,
            'is-valid-custom': !_vm.$v.selectedDistrict.$invalid,
          },"invalidMessage":[
            !_vm.$v.selectedDistrict.required ? 'Butuh daerah kamu nih' : null,
          ]},on:{"input":_vm.getSubDistrictList},model:{value:(_vm.$v.selectedDistrict.$model),callback:function ($$v) {_vm.$set(_vm.$v.selectedDistrict, "$model", $$v)},expression:"$v.selectedDistrict.$model"}}),_c('MyInputFormSelect',{attrs:{"disabled":_vm.subDistrictList.length == 0,"id":"sub-district","label":"Select Sub District *","options":_vm.subDistrictList,"placeholder":"Select Sub District *","classComponent":{
            'is-invalid-custom': _vm.$v.selectedSubDistrict.$error,
            'is-valid-custom': !_vm.$v.selectedSubDistrict.$invalid,
          },"invalidMessage":[
            !_vm.$v.selectedSubDistrict.required
              ? 'Butuh kecamatan kamu kamu nih'
              : null,
          ]},model:{value:(_vm.$v.selectedSubDistrict.$model),callback:function ($$v) {_vm.$set(_vm.$v.selectedSubDistrict, "$model", $$v)},expression:"$v.selectedSubDistrict.$model"}}),_c('MyInputFormText',{attrs:{"id":"postal-code","label":"Postal Code","placeholder":"Postal Code","maxlength":5,"classComponent":{
            'is-invalid-custom': _vm.$v.postalCode.$error,
            'is-valid-custom': !_vm.$v.postalCode.$invalid,
          },"invalidMessage":[
            !_vm.$v.postalCode.required ? 'Butuh kode postal kamu nih' : null,
            !_vm.$v.postalCode.numeric && _vm.$v.postalCode.required
              ? 'Masukan nomor yu!'
              : null,
            !_vm.$v.postalCode.minLength
              ? `Kode postal kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.postalCode.$params.minLength.min}`
              : null,
          ],"redAsterisk":"true"},model:{value:(_vm.$v.postalCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.postalCode, "$model", $$v)},expression:"$v.postalCode.$model"}}),_c('h5',{staticClass:"no-margin-bottom"},[_vm._v("Courier selection")]),_c('p',[_vm._v(" Select agents couriers and delivery services you want to use (could be more than one). ")]),_c('div',{staticClass:"row d-flex justify-content-center p-1"},_vm._l((_vm.courierList),function(courier,index){return _c('div',{key:index,staticClass:"col-3 p-1"},[_c('div',{staticClass:"border p-1",class:{ 'bg-info': _vm.handleModel(courier.id, true) != -1 },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleModel(courier.id)}}},[_c('img',{attrs:{"src":courier.image_url,"alt":""}}),_c('br'),_vm._v(" "+_vm._s(courier.name)+" ")])])}),0),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-8 offset-md-2"},[_c('p',{staticClass:"have-account mb-0"},[_vm._v(" By clicking the 'Register' below button I agree to the "),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-term'),expression:"'modal-term'"}],attrs:{"href":"javascript:void(0)"}},[_vm._v("Terms of Use")]),_vm._v(" of this site ")])])])]),_vm._m(0)],1)]),_c('br'),_c('br')]),_c('ModalTermsOfService')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container my-3"},[_c('div',{staticClass:"col-md-12 text-center"},[_c('button',{staticClass:"btn btn-wz-proceed",staticStyle:{"display":"inline","width":"65%"}},[_vm._v(" REGISTER ")])])])
}]

export { render, staticRenderFns }