<template>
  <div class="form-group">
    <div class="clearfix">
      <div class="input-line">
        <select
          :id="id"
          v-model="model"
          class="form-control-custom"
          :class="classComponent"
          @input="input($event.target.value)"
          :disabled="disabled"
        >
          <option value="null" disabled>
            <slot name="placeholder">
              {{placeholder || "Please select an option"}}
            </slot>
          </option>
          <option v-for="option in localOptions" :key="option.value" :value="option.value">
            {{option.text}}
          </option>
        </select>
        <div class="invalid-feedback text-right">
          {{invalidMessage && invalidMessage.length ? invalidMessage.join('\n') : ''}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "value",
    "id",
    "label",
    "options",
    "classComponent",
    "invalidMessage",
    "placeholder",
    "disabled",
  ],
  data() {
    return {
      model: this.value,
      localOptions: this.options,
    };
  },
  methods: {
    input(val) {
      this.model = val;
      this.$emit("input", val);
    },
  },
  watch: {
    value(newValue) {
      this.model = newValue
    },
    options(newValue) {
      this.localOptions = newValue
    }
  }
};
</script>
